.retailers-page {
    margin: 0 auto;
    padding: 20px;
    background-color: #444; /* Dark background color */
    color: #fff; /* Text color */
    height: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .action-buttons {
    display: flex;
  }
  
  .action-buttons .button {
    margin-left: 10px;
  }
  
  .content {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff; /* Bright table background color */
    color: #222; /* Table text color */
  }
  
  .content h2 {
    margin-bottom: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .logout {
    width: 80px;
  }